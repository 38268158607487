import react from "react";
import "../styles/empty-styles.css";
import Logo from ".././../assests/svg/Logo.svg";

const EmptyView = () => {
  return (
    <div className="empty-wrapper">
      <div className="empty-wrapper-info">
        <div className="logo-wrapper">
          <img src={Logo} alt="location" />
        </div>
        <div className="text-wrapper">
          <div className="title-text">{"Hi, Welcome"}</div>
          <div className="desc-text">
            {
              "You are visiting Netlise menu platform to view your favourite restaurant menus."
            }
          </div>
          <div className="desc-text">
            {"Currently you do not have any menu selected."}
          </div>
          <div className="desc-text">
            {"Scan a menu from QR or click from a link to view the menu"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmptyView;
