export const isProd = true;
export const GOOGLE_KEY = "AIzaSyAZdW85BVBFMlUcsuJeFphwoTTphB8_U2I";

const config = {
  Auth: {
    mandatorySignIn: false,
    region: "ap-southeast-1",
    identityPoolRegion: "ap-southeast-1",
    userPoolId: "ap-southeast-1_HKRnVLeRh",
    authenticationFlowType: "USER_PASSWORD_AUTH",
    userPoolWebClientId: "799up0e2pd4fr49au318d9lou9",
    identityPoolId: "ap-southeast-1:424c5096-4794-4cb1-b9f3-37bcc413a2bb",
  },
  aws_appsync_region: "ap-southeast-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint:
    "https://rmwldfvcgfgfpcqttl4ns6v55a.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  API: {
    region: "ap-southeast-1",
    endpoints: [],
  },
  Storage: {
    AWSS3: {
      region: "ap-southeast-1",
      bucket: "netlise-image-bucket",
    },
  },
};

const prodConfig = {
  Auth: {
    mandatorySignIn: false,
    region: "ap-southeast-1",
    identityPoolRegion: "ap-southeast-1",
    userPoolId: "ap-southeast-1_evHs6b0TZ",
    authenticationFlowType: "USER_PASSWORD_AUTH",
    userPoolWebClientId: "2t1924caluk976ar6l53qcuoag",
    identityPoolId: "ap-southeast-1:2fb4e791-d71d-4a6f-b424-3b3d0fbf3f1a",
  },
  aws_appsync_region: "ap-southeast-1",
  aws_appsync_authenticationType: "AWS_IAM",
  aws_appsync_graphqlEndpoint:
    "https://r5denx53k5evvawmy6c3wmf22q.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  API: {
    endpoints: [],
    region: "ap-southeast-1",
  },
  Storage: {
    AWSS3: {
      region: "ap-southeast-1",
      bucket: "netlise-app-bucket",
    },
  },
};
export default isProd ? prodConfig : config;
