import {useState, createContext} from 'react';
import {filter, find, findIndex, get, isArray, isEmpty, sortBy} from 'lodash';

export const ProductContext = createContext<{
  qrId?: any;
  shop?: any;
  menu?: any;
  carts?: any;
  qrData?: any;
  products?: any;
  shortCode?: any;
  tableAreas?: any;
  loadingMenu?: boolean;
  selectedProduct?: any;
  loadingProduct?: boolean;
  setQrId: (id: string) => void;
  fetchMenu: (sid: string) => void;
  fetchShop: (sid: string) => void;
  setShortCode: (id: string) => void;
  setSelectedProduct: (data: any) => void;
  fetchDataForQrId: (qrId: string) => void;
  onUpdateCart: (data: any, type: string) => void;
  fetchProducts: (sid: string, type: string) => void;
  fetchProductForId: (sid: string, pid: string) => void;
}>({
  shop: {},
  carts: [],
  menu: [],
  qrData: {},
  products: [],
  tableAreas: {},
  setQrId: () => {},
  loadingMenu: false,
  selectedProduct: {},
  fetchShop: () => {},
  fetchMenu: () => {},
  loadingProduct: false,
  onUpdateCart: () => {},
  setShortCode: () => {},
  fetchProducts: () => {},
  fetchDataForQrId: () => {},
  fetchProductForId: () => {},
  setSelectedProduct: () => {},
});

const ProductContextProvider = ({children}: any) => {
  const [productLimit, setProductLimit] = useState(0);
  const [products, setProducts] = useState<Array<any>>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [loadingProduct, setLoadingProduct] = useState<boolean>(false);
  const [disabledFetchProduct, setDisabledFetchProduct] =
    useState<boolean>(false);

  const [shop, setShop] = useState<any>({});
  const [menu, setMenu] = useState<any>([]);
  const [qrData, setQrData] = useState<any>({});
  const [carts, setCarts] = useState<Array<any>>([]);
  const [tableAreas, setTableAreas] = useState<any>({});
  const [qrId, setQrId] = useState<string | undefined>();
  const [loadingMenu, setLoadingMenu] = useState<boolean>(false);
  const [shortCode, setShortCode] = useState<string | undefined>();

  const onUpdateCart = (data: any, type: string) => {
    let newcarts = [...carts];

    if (data.priceVariant) {
    }

    const index = isArray(data.priceVariant)
      ? -1
      : findIndex(carts, (item) => item.pid === data.pid);
    if (type === 'ADD') {
      if (index > -1) {
        newcarts[index].quantity += 1;
        newcarts[index].amount =
          newcarts[index].quantity *
          parseFloat(
            newcarts[index].itemSalePrice || newcarts[index].itemPrice
          );
        setSelectedProduct(newcarts[index]);
      } else {
        const params = {...data};
        params['quantity'] += 1;
        params['amount'] =
          params.quantity *
          parseFloat(params.itemSalePrice || params.itemPrice);
        setSelectedProduct({...params});
      }
    } else if (type === 'SET') {
      if (!isEmpty(newcarts)) {
        newcarts.push(data);
      } else {
        newcarts = [{...data}];
      }
    } else {
      if (index > -1) {
        if (newcarts[index].quantity > 1) {
          newcarts[index].quantity -= 1;
          newcarts[index].amount =
            newcarts[index].quantity *
            parseFloat(
              newcarts[index].itemSalePrice || newcarts[index].itemPrice
            );
          setSelectedProduct(newcarts[index]);
        } else {
          const filterArray = filter(
            newcarts,
            (item) => item.apiddId !== data.pid
          );
          newcarts = [...filterArray];
          setSelectedProduct({...data});
        }
      } else {
        const params = {...data};
        if (params.quantity > 1) {
          params.quantity -= 1;
          params.amount =
            params.quantity *
            parseFloat(params.itemSalePrice || params.itemPrice);
          setSelectedProduct(params);
        } else {
          setSelectedProduct(params);
        }
      }
    }
    setCarts([...newcarts]);
  };

  const setData = (type: string, data: any) => {
    if (type === 'GET') {
      setProducts(data);
      setProductLimit(0 + data.length);
    } else {
      setProductLimit(productLimit + data.length);
      if (!isEmpty(products)) {
        setProducts([...products, ...data]);
      } else {
        setProducts([...data]);
      }
    }
    setLoadingProduct(false);
  };

  const setMenuData = (menu: Array<any>) => {
    setMenu(sortBy(menu, 'position'));
  }

  const fetchShop = async (sid: string) => {
    fetch(
      `https://5y1hnsmcq9.execute-api.ap-southeast-1.amazonaws.com/dev/shop?slug=${sid}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (get(data, 'status', '') === 'SUCCESS') {
          setShop(get(data, 'shopResult', {}));
        }
      });
  };

  const fetchMenu = async (sid: string) => {
    fetch(
      `https://5y1hnsmcq9.execute-api.ap-southeast-1.amazonaws.com/dev/menu?slug=${sid}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (get(data, 'status', '') === 'SUCCESS') {
          setMenu(sortBy(get(data, 'data', []), 'position'));
        }
      });
  };

  const fetchProducts = async (sid: string, type: string) => {
    setLoadingProduct(true);
    fetch(
      'https://4qawsqx576.execute-api.ap-southeast-1.amazonaws.com/dev/products',
      {
        method: 'POST',
        body: JSON.stringify({sid, limit: -1}),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (!isEmpty(data)) {
          setData(type, get(data, 'productResult.Items', []));
          setDisabledFetchProduct(data.length < 10);
        } else {
          if (type === 'GET') {
            setProducts([]);
          }
          setLoadingProduct(false);
          setDisabledFetchProduct(true);
        }
      });
  };

  const fetchProductForId = async (shopId: string, pid: string) => {
    setLoadingProduct(true);
    fetch(
      `https://5y1hnsmcq9.execute-api.ap-southeast-1.amazonaws.com/dev/product?shopId=${shopId}&pid=${pid}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!isEmpty(data)) {

          const findProduct = data.product;
          findProduct["quantity"] = 1;
          findProduct["amount"] =
            findProduct.quantity *
            parseFloat(findProduct.itemSalePrice || findProduct.itemPrice);

          setMenuData([findProduct]);
          setSelectedProduct(findProduct);
          setLoadingProduct(false);
        } else {
          setProducts([]);
          setLoadingProduct(false);
          setDisabledFetchProduct(true);
        }
      });
  };

  const fetchDataForQrId = async (qrId: string) => {
    setLoadingMenu(true);
    fetch(
      'https://5y1hnsmcq9.execute-api.ap-southeast-1.amazonaws.com/dev/shop/qrcode',
      {
        method: 'POST',
        body: JSON.stringify({qrId}),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (get(data, 'status', '') === 'SUCCESS') {
          setShop(get(data, 'data.shop', {}));
          setQrData(get(data, 'data.qrData', {}));
          setTableAreas(get(data, 'data.tableAreas', {}));
          setProducts(get(data, 'data.products.Items', []));
          setMenuData(get(data, 'data.menus', []));
          setLoadingMenu(false);
        }
      });
  };

  const createOrder = async (params: any) => {};

  return (
    <ProductContext.Provider
      value={{
        shop,
        menu,
        qrId,
        carts,
        qrData,
        setQrId,
        products,
        shortCode,
        fetchMenu,
        fetchShop,
        tableAreas,
        loadingMenu,
        onUpdateCart,
        setShortCode,
        fetchProducts,
        loadingProduct,
        selectedProduct,
        fetchDataForQrId,
        fetchProductForId,
        setSelectedProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContextProvider;
