import { isString } from "lodash";
import { redirect, useParams } from "react-router-dom";

const TripAdvisorLink = () => {
  const params = useParams();

  if (isString(params?.shortCode) && links[params.shortCode]) {
    window.location.replace(links[params.shortCode]);
    return null;
  } else {
    return <div />;
  }
};

const links: any = {
  taphouse:
    "https://www.tripadvisor.com/UserReviewEdit-g297896-d8677611-Tap_House_By_Trio-Galle_Galle_District_Southern_Province.html",
};

export default TripAdvisorLink;
