import React from "react";
import Modal from "react-modal";
import { get, isEmpty } from "lodash";
import "../../modules/styles/restaurant-styles.css";
import { getPrice } from "../../initialize/managers/Currency";

const customStyles: any = {
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  content: {
    left: "unset",
    top: "unset",
    right: "unset",
    bottom: "1px",
    width: "100%",
    border: "none",
    padding: "0px",
    height: "370px",
    overflow: "auto",
    maxWidth: "768px",
    background: "#fff",
    borderRadius: "0px",
    position: "absolute",
    WebkitOverflowScrolling: "touch",
    boxShadow: "0 4px 8px 1px rgb(0 0 0 / 0.8)",
  },
};

const CartModal = ({ shop, carts, isOpen, onClose }: any) => {
  const total = carts?.reduce((accumulator: number, object: any) => {
    return accumulator + parseFloat(object.amount);
  }, 0);
  const totalPrice = getPrice({ price: total });

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Cart Modal"
      onRequestClose={() => onClose(false)}
    >
      <div className="bottom-sheet-wrapper">
        <div className="bottom-sheet-restaurant-text">
          {get(shop, "shopName", "")}
        </div>
        <div className="bottom-sheet-line" />
        {!isEmpty(carts) ? (
          <div className="bottom-sheet-main-wrapper">
            <>
              <div className="bottom-sheet-cart-scroll">
                {carts?.map((item: any, index: number) => {
                  const price = getPrice({
                    price: item.itemSalePrice || item.itemPrice,
                  });

                  return (
                    <>
                      <div key={index} className="bottom-sheet-item-wrapper">
                        <div className="restaurant-row-line-wrapper">
                          <div className="bottom-sheet-item-index">
                            {item.quantity}
                          </div>
                          <div className="restaurant-item-text">
                            {item.productName}
                          </div>
                        </div>
                        <div className="bottom-sheet-price-text">{price}</div>
                      </div>
                      <div className="bottom-sheet-item-line" />
                    </>
                  );
                })}
              </div>
              <div className="bottom-sheet-item-wrapper">
                <div className="restaurant-item-text">{"Sub Total"}</div>
                <div className="bottom-sheet-price-text">{totalPrice}</div>
              </div>
            </>
            {/* <button
              onClick={() => onClose(false)}
              className="bottom-sheet-button"
            >
              {"Create order"}
            </button> */}
          </div>
        ) : (
          <div className="bottom-sheet-empty-wrapper">
            <div className="bottom-sheet-empty-text">{"Empty carts"}</div>
            <div className="bottom-sheet-empty-title-text">
              {"Please select from the carts at home"}
            </div>
            <button
              onClick={() => onClose(false)}
              className="bottom-sheet-empty-button"
            >
              {"Back to Store"}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default CartModal;
