import { useContext, useEffect } from "react";
import { get } from "lodash";
import "../styles/home-styles.css";
import { DotLoader } from "react-spinners";
import Logo from ".././../assests/svg/Logo.svg";
import { ProductContext } from "../restaurant/Context";
import { useNavigate, useParams } from "react-router-dom";

const WelcomeView = () => {
  const params = useParams();
  let navigate = useNavigate();
  const { shop, setQrId, loadingMenu, setShortCode, fetchDataForQrId } =
    useContext(ProductContext);

  useEffect(() => {
    if (params && params.shortCode) {
      let qrId;
      if (params.id) {
        qrId = `${params.shortCode}/${params.id}`;
        setQrId(qrId);
      } else {
        qrId = `${params.shortCode}`;
      }
      fetchDataForQrId(qrId);
      setShortCode(params.shortCode);
    }
  }, [params, navigate]);

  const onClickMenu = () => {
    if (shop) {
      navigate(`/restaurant/${shop.shortCode}`);
    }
  };

  return (
    <div className="home-wrapper">
      <div className="home-wrapper-info">
        <div className="home-header-wrapper">
          <div className="logo-wrapper">
            <img src={Logo} alt="location" />
          </div>
          <div className="text-wrapper">
            <div className="desc-text">{"Welcome to "}</div>
            <div className="title-text">{`${get(shop, "shopName", "")}`}</div>
          </div>
        </div>
        <div className="home-header-wrapper">
          <button onClick={() => onClickMenu()} className="button">
            {loadingMenu ? (
              <DotLoader color={"#6D71F9"} loading={loadingMenu} size={25} />
            ) : (
              "See menu"
            )}
          </button>
          <div onClick={() => {}} className="bottom-text">
            {"Your past visits"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WelcomeView;
