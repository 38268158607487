import "../../modules/styles/restaurant-styles.css";
import ContentLoader from "react-content-loader";

export const RestaurantLoading = () => {
  return (
    <div className="restaurant-details-loading-wrapper">
      <ContentLoader
        speed={1}
        height={90}
        viewBox="0 0 100% 90"
        style={{ width: "calc(100% - 34px)" }}
      >
        <rect
          x="0"
          y="0"
          rx="3"
          ry="3"
          height="15"
          width="calc(100% - 100px)"
        />
        <rect
          y="0"
          rx="3"
          ry="3"
          width="100"
          height="15"
          x="calc(100% - 90px)"
        />
        <rect x="0" y="30" rx="3" ry="3" width="90%" height="10" />
        <rect x="0" y="50" rx="3" ry="3" width="60" height="15" />
        <rect
          y="50"
          rx="3"
          ry="3"
          x="80"
          height="15"
          width="calc(100% - 80px)"
        />
      </ContentLoader>
    </div>
  );
};

export const MenuLoading = () => {
  return (
    <div className="restaurant-menu-loading-wrapper">
      <ContentLoader
        speed={1}
        height={36}
        viewBox="0 0 100% 36"
        style={{ width: "100%" }}
      >
        <rect x="0" y="0" rx="3" ry="3" width="100" height="28" />
        <rect
          y="0"
          rx="3"
          ry="3"
          x="140"
          height="28"
          width="calc(100% - 140px)"
        />
      </ContentLoader>
    </div>
  );
};

export const ItemLoading = () => {
  return (
    <>
      {[1, 2, 3].map((data) => {
        return (
          <div key={data} className="restaurant-item-loading-wrapper">
            <ContentLoader
              speed={1}
              height={50}
              viewBox="0 0 100% 50"
              style={{ width: "100%" }}
            >
              <rect x="0" y="0" rx="10" ry="10" height="50" width="50" />
              <rect y="5" rx="3" ry="3" x="60" width="200" height="20" />
              <rect y="30" rx="3" ry="3" x="60" width="200" height="15" />
              <rect
                y="20"
                rx="3"
                ry="3"
                x="calc(100% - 100px)"
                height="15"
                width="100"
              />
            </ContentLoader>
          </div>
        );
      })}
    </>
  );
};

export const DetailsLoading = () => {
  return (
    <div className="restaurant-details-loading-wrapper">
      <ContentLoader
        speed={1}
        height={330}
        viewBox="0 0 100% 330"
        style={{ width: "100%" }}
      >
        <rect
          x="calc(50% - 150px)"
          y="0"
          rx="10"
          ry="10"
          height="239"
          width="260"
        />
        <rect
          y="260"
          rx="3"
          ry="3"
          x="calc(50% - 120px)"
          width="200"
          height="30"
        />
        <rect
          y="300"
          rx="3"
          ry="3"
          x="calc(50% - 100px)"
          width="160"
          height="20"
        />
      </ContentLoader>
    </div>
  );
};

export const TitleLoading = () => {
  return (
    <div className="restaurant-details-loading-wrapper">
      <ContentLoader
        speed={1}
        height={65}
        viewBox="0 0 80% 65"
        style={{ width: "80%" }}
      >
        <rect x="0" y="0" rx="4" ry="4" height="17" width="100%" />
        <rect x="0" y="23" rx="4" ry="4" height="17" width="100%" />
        <rect x="0" y="46" rx="4" ry="4" height="17" width="100%" />
      </ContentLoader>
    </div>
  );
};
