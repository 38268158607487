import restaurant from '.././../assests/image/restaurant-image.png';

const Image = ({menuImage, alt}: {menuImage: string, alt: string}) => {
  return (
    <div style={{width: '25vw'}}>
      <img
        src={
          menuImage
            ? `https://dm6mmpk56rvc9.cloudfront.net/products/${menuImage}`
            : restaurant
        }
        alt={alt}
        style={{
          fontSize: '10px',
          color: '#999',
          width: '25vw',
          maxWidth: '232px',
          maxHeight: '232px',
          height: '25vw',
          objectFit: 'cover',
          objectPosition: 'center',
          borderRadius: '8px',

        }}
      />
    </div>
  );
};

export default Image;
