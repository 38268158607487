import {useContext, useEffect, useState} from 'react';
import '../styles/restaurant-styles.css';
import {ProductContext} from './Context';
import {find, findIndex, get, isEmpty, startCase, values} from 'lodash';
import plus from '../../assests/svg/icon-plus.svg';
import minus from '../../assests/svg/icon-minus.svg';
import {useNavigate, useParams} from 'react-router-dom';
import BackButton from '../../components/button/BackButton';
import {getMenuItemPrice, getPrice} from '../../initialize/managers/Currency';
import FormTextArea from '../../components/input/FormTextArea';
import restaurant from '.././../assests/image/restaurant-image.png';
import {DetailsLoading, TitleLoading} from '../../components/loading/Loading';
import useImage from '../../initialize/hooks/useImage';
import { Helmet } from 'react-helmet';

const MenuItemView = () => {
  let navigate = useNavigate();
  const params = useParams();
  const {
    shop,
    carts,
    fetchShop,
    fetchMenu,
    loadingProduct,
    selectedProduct,
    fetchProductForId,
  } = useContext(ProductContext);
  const [selectedCart, setSelectedCart] = useState<any>({});
  const [selectedOptions, setSelectedOptions] = useState<any>({});
  const [instructions, setInstructions] = useState<string>('');

  const image = useImage(shop.menuImage);
  const isCart = selectedProduct?.priceVariant
    ? findIndex(carts, (item: any) => item?.pid === selectedProduct.pid) > -1
    : false;

  useEffect(() => {
    if (selectedProduct) {
      const cart: any = find(
        carts,
        (cart: any) => cart.pid === selectedProduct.pid
      );
      setSelectedCart(cart);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (params && params.shopId && params.pid) {
      const shopId: string = params.shopId;
      const pid: string = params.pid;
      fetchShop(shopId);
      fetchMenu(shopId);
      fetchProductForId(shopId, pid);
    }
  }, [params, navigate]);

  // console.log({selectedOptions: values(selectedOptions), selectedProduct});

  const onSelectOption = (optionSetId: string, option: string) => {
    selectedOptions[optionSetId] = option;
    setSelectedOptions({...selectedOptions});
    // const index = selectedOptions.findIndex((op: string) => option === op);
    // if (index > -1) {
    //   setSelectedOptions(selectedOptions.splice(index, 1));
    // } else {
    //   setSelectedOptions([...selectedOptions, option]);
    // }
  };

  const itemPrice = getMenuItemPrice(selectedProduct, selectedOptions);
  // console.log({itemPrice, carts});
  const price = itemPrice ? getPrice({price: itemPrice}) : '-';

  return (
    <div className="menu-wrapper">
      <Helmet>
        <title>{`${startCase(selectedProduct.productName)} of ${get(
          shop,
          'shopName',
          ''
        )} - Netlise Menu App`}</title>
        <meta
          title={`${startCase(selectedProduct.productName)} of ${get(
            shop,
            'shopName',
            ''
          )} - Netlise Menu App`}
        />
        <meta
          name="description"
          content={`${selectedProduct.description}, Drinks and Food menu of ${get(
            shop,
            'shopName',
            ''
          )}`}
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="menu-wrapper-info">
        <div
          style={{overflow: 'hidden'}}
          className="menu-background-image-wrapper"
        >
          <img src={image || restaurant} alt="" className="menu-image" />
        </div>

        <div className="menu-bottom-wrapper">
          <BackButton />
          <div className="menu-item-main-wrapper">
            {!loadingProduct ? (
              <div className="menu-item-image-wrapper">
                <div className="menu-item-image">
                  <img
                    src={
                      selectedProduct.menuImage
                        ? `https://dm6mmpk56rvc9.cloudfront.net/products/${selectedProduct.menuImage}`
                        : restaurant
                    }
                    alt={selectedProduct.productName}
                    style={{
                      width: '285px',
                      height: '285px',
                      borderRadius: '16px',
                      objectFit: 'cover',
                      objectPosition: 'center',
                    }}
                  />
                </div>
                <div className="menu-item-text">
                  {startCase(selectedProduct.productName)}
                </div>
                <div className="menu-item-price">{price}</div>
              </div>
            ) : (
              <DetailsLoading />
            )}
          </div>
        </div>
        <div className="menu-item-title-wrapper">
          {!loadingProduct ? (
            <div className="menu-item-description">
              {selectedProduct.description}
            </div>
          ) : (
            <TitleLoading />
          )}

          <div>
            {selectedProduct.productOptions?.map(
              ({id, options, setDisplayName}: any) => (
                <div className="price-variants" key={id}>
                  <h3>{setDisplayName}</h3>
                  <div className="options">
                    {options.map((option: any) => (
                      <div
                        key={option.id}
                        onClick={() => {
                          onSelectOption(id, option.id);
                        }}
                        className={
                          option.id === selectedOptions[id] ? 'selected' : ''
                        }
                        // className={`${selectedOptions.find((op: string) => op === option.id) ? 'selected': ''}`}
                      >
                        {option.optionName}
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>

          {/* <div className="menu-item-input-title">{'Special Instructions'}</div>
          <FormTextArea
            value={instructions}
            onInputchange={(e) => setInstructions(e.target.value)}
          />
          <div className="menu-item-input-title">{'Quantity'}</div>
          <div className="menu-item-row-wrapper">
            <div className="quantity-text">
              {isEmpty(selectedCart)
                ? selectedProduct?.quantity
                : selectedCart?.quantity}
            </div>
            <div className="quantity-wrapper">
              <div
                onClick={() => onUpdateCart(selectedProduct, 'REMOVE')}
                className="quantity-button"
              >
                <img src={minus} alt="minus" />
              </div>
              <div
                onClick={() => onUpdateCart(selectedProduct, 'ADD')}
                className="quantity-button"
              >
                <img src={plus} alt="plus" />
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className="cart-button-wrapper">
          <button
            onClick={() => {
              if (!isCart) {
                onUpdateCart(selectedProduct, 'SET');
              }
            }}
            style={{backgroundColor: isCart ? '#707070' : '#fd9426'}}
            className="cart-button"
          >
            {'Add to Cart'}
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default MenuItemView;
