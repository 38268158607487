import { useEffect, useState } from "react";

const useImage = (uri: string | null) => {
  const [image, setImage] = useState<null | string>(null);

  const getLink = async (uri: any) => {
    const link = `https://dm6mmpk56rvc9.cloudfront.net/${uri}`;
    if (link) {
      setImage(link);
    }
  };

  useEffect(() => {
    if (uri) {
      getLink(uri);
    }
  }, [uri]);

  return image;
};

export default useImage;
