import {difference, first, isEqual, last, sortBy, values} from 'lodash';

export const getPrice = ({
  price,
  currency = 'LKR',
}: {
  price: number;
  currency?: string;
}) => {
  return new Intl.NumberFormat('en-IN', {
    currency,
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(price);
};

export const getProductPrice = (product: any) => {
  if (product?.priceVariants) {
    const variants = sortBy(product?.priceVariants, 'price');
    return `${getPrice({price: variants[0].price})} - ${getPrice({
      price: last(variants).price,
    })}`;
  }
  return getPrice({price: product.itemPrice});
};

export const getMenuItemPrice = (
  selectedProduct: any,
  selectedOptions: Array<string>
) => {
  if (selectedProduct?.priceVariants) {
    const priceVariant = selectedProduct?.priceVariants?.find((variant: any) =>
      isEqual(sortBy(values(selectedOptions)), sortBy(variant.options))
    );

    return priceVariant ? priceVariant.price : null;
  }
  return selectedProduct.amount;
};
