import React from "react";
import "../../modules/styles/restaurant-styles.css";
import search from ".././../assests/svg/search.svg";

const SearchInput = ({
  value,
  onInputchange,
}: {
  value: any;
  onInputchange: (element: string) => void;
}) => {
  return (
    <div className="search-input-wrapper">
      <input
        type="text"
        value={value}
        className="search-input"
        onChange={(e)=> onInputchange(e.target.value)}
      />
      <img src={search} alt="location" style={{ width: "13px" }} />
    </div>
  );
};

export default SearchInput;
