import {useContext, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import '../styles/restaurant-styles.css';
import {
  ItemLoading,
  MenuLoading,
  RestaurantLoading,
} from '../../components/loading/Loading';
import {Parallax} from 'react-parallax';
import {ProductContext} from './Context';
import {DotLoader} from 'react-spinners';
import star from '.././../assests/svg/star-fill.svg';
import calling from '.././../assests/svg/calling.svg';
import useImage from '../../initialize/hooks/useImage';
import location from '.././../assests/svg/location.svg';
import CartModal from '../../components/modal/CartModal';
import {useNavigate, useParams} from 'react-router-dom';
import SearchInput from '../../components/input/SearchInput';
import {getPrice, getProductPrice} from '../../initialize/managers/Currency';
import {
  get,
  groupBy,
  isEmpty,
  keyBy,
  startCase,
  truncate,
  uniqBy,
} from 'lodash';
import restaurant from '.././../assests/image/restaurant-image.png';
import Image from '../../components/images/Image';

const RestaurantView = () => {
  const params = useParams();
  let navigate = useNavigate();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([]);
  const [categoryMap, setCategoryMap] = useState<any>({});
  const [productMap, setProductMap] = useState<any>({});
  const [groupedProducts, setGroupedProducts] = useState<any>({});
  const [searchText, setSearchText] = useState<string>('');
  const [selectedShop, setSelectedShop] = useState<any>({});
  const [searchCategory, setSearchCategory] = useState<Array<any>>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [selectedMenu, setSelectedMenu] = useState<any>({});
  const {
    shop,
    menu,
    carts,
    products,
    fetchDataForQrId,
    loadingProduct,
    setSelectedProduct,
  } = useContext(ProductContext);
  const image = useImage(shop.menuImage);

  useEffect(() => {
    if (params && params.shopId) {
      const shopId: string = params.shopId;
      fetchDataForQrId(shopId);
      // fetchShop(shopId);
      // fetchMenu(shopId);
      // fetchProducts(shopId, 'GET');
    }
  }, [params, navigate]);

  useEffect(() => {
    if (!isEmpty(shop)) {
      setSelectedShop(shop);
    }
  }, [shop]);

  useEffect(() => {
    if (!isEmpty(products)) {
      const filteredProducts = products.filter(({showMenu}: any) => showMenu);
      const availableCategories = uniqBy(
        filteredProducts,
        (item: any) => item.categoryId
      ).map((product) => product.categoryResult);
      setCategories([
        {categoryName: 'All', categoryId: 'ALL'},
        ...availableCategories,
      ]);

      setCategoryMap(keyBy(availableCategories, 'categoryId'));
      setProductMap(keyBy(filteredProducts, 'pid'));

      let groupProduct = groupBy(filteredProducts, (item) => item.categoryId);
      groupProduct = {ALL: filteredProducts, ...groupProduct};
      setGroupedProducts(groupProduct);

      setSelectedCategory({categoryName: 'All', categoryId: 'ALL'});
    }
  }, [products]);

  useEffect(() => {
    if (!isEmpty(menu)) {
      setSelectedMenu(menu[0]);
    }
  }, [menu]);

  const onClickProduct = (data: any) => {
    const product = {...data};
    product['quantity'] = 1;
    product['amount'] = data.itemSalePrice || data.itemPrice;
    setSelectedProduct(product);
    navigate(`${product.pid}`);
  };

  const onInputchange = (text: string) => {
    setSearchText(text);
  };

  useEffect(() => {
    if (searchText.length > 0) {
      const categoryProducts = groupedProducts[selectedCategory.categoryId];
      const filteredData = categoryProducts.filter((item: any) => {
        return item.productName
          .toLowerCase()
          .includes(searchText.toLocaleLowerCase());
      });
      setSearchCategory([...filteredData]);
    }
  }, [searchText, selectedCategory]);

  return (
    <div className="restaurant-wrapper">
      <Helmet>
        <title>{`Menu of ${get(
          selectedShop,
          'shopName',
          ''
        )} - Netlise Menu App`}</title>
        <meta
          title={`Menu of ${get(
            selectedShop,
            'shopName',
            ''
          )} powered by Netlise Menu App`}
        />
        <meta
          name="description"
          content={`Drinks and Food menu of ${get(
            selectedShop,
            'shopName',
            ''
          )} located at ${get(selectedShop, 'address', '')}`}
        />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="restaurant-wrapper-info">
        {!loadingProduct ? (
          <Parallax
            bgImage={image || restaurant}
            bgImageStyle={{
              objectPosition: 'center',
              objectFit: 'cover',
              height: '100%',
            }}
            renderLayer={(percentage) => (
              <div
                style={{
                  background: '#ffffff',
                  height: percentage * 340,
                }}
              />
            )}
            style={{maxWidth: '768px', width: '100vw'}}
          />
        ) : (
          <div className="restaurant-image-loading">
            <DotLoader color={'#fd9426'} loading={true} size={25} />
          </div>
        )}

        <div className="restaurant-bottom-wrapper">
          <div className="restaurant-bottom-line-wrapper">
            <div className="restaurant-bottom-line" />
          </div>

          <div className="restaurant-scroll-wrapper">
            <div className="restaurant-bottom-wrapper-info">
              {!loadingProduct ? (
                <>
                  <div className="restaurant-row-wrapper">
                    <div className="restaurant-text">
                      {get(selectedShop, 'shopName', '')}
                    </div>
                    <div className="restaurant-row-line-wrapper ">
                      <img
                        src={star}
                        alt="rating"
                        style={{width: '10px', marginRight: '10px'}}
                      />
                      <div className="restaurant-rating-text">
                        {get(selectedShop, 'rating', '0')}
                      </div>
                    </div>
                  </div>
                  {/* <div className="restaurant-type-text">
                    {"Breakfasts, Brunch, Dinner"}
                  </div> */}
                  <div className="restaurant-row-wrapper">
                    <div className="restaurant-row-line-wrapper ">
                      <img
                        src={location}
                        style={{width: '10px', marginRight: '10px'}}
                      />
                      <div className="restaurant-address-text">
                        {get(selectedShop, 'address', '')}
                      </div>
                    </div>
                    <div
                      className="restaurant-row-line-wrapper"
                      style={{flexShrink: 0}}
                    >
                      <img
                        src={calling}
                        alt="mobile"
                        style={{marginRight: '15px'}}
                      />
                      <div className="restaurant-menu-header">
                        {get(selectedShop, 'phoneNumber', '')}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <RestaurantLoading />
              )}

              {/* <div className="restaurant-row-wrapper restaurant-header-margin">
                <div className="restaurant-header-text">{"Offers & deals"}</div>
                <div className="restaurant-see-all-text">{"See all"}</div>
              </div> */}
              {/* {products?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => onClickProduct(item)}
                    className="restaurant-row-wrapper restaurant-item-margin"
                  >
                    <div className="restaurant-row-line-wrapper">
                      <div className="restaurant-item-wrapper">
                        <img
                          src={restaurant}
                          alt="location"
                          style={{
                            width: "47px",
                            height: "44px",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                      <div className="restaurant-column-line-wrapper restaurant-item-text-margin">
                        <div className="restaurant-item-text">
                          {item.productName}
                        </div>
                        <div className="restaurant-item-type-text">
                          {"Served with a side of steamed rice"}
                        </div>
                      </div>
                    </div>
                    <div className="restaurant-discount-text">{"10% off"}</div>
                  </div>
                );
              })} */}
            </div>

            {!loadingProduct ? (
              <>
                <div className="restaurant-menu-wrapper">
                  <div className="restaurant-menu-header">{'Our menu'}</div>
                  <div className="restaurant-menu-search">
                    <SearchInput
                      value={searchText}
                      onInputchange={(text: string) => onInputchange(text)}
                    />
                  </div>
                </div>

                <div className="restaurant-menu-item-wrapper">
                  <div className="restaurant-menu-wrapper-info">
                    {menu.map((item: any, index: number) => {
                      const {title, menuId} = item;
                      const isSelected = selectedMenu?.menuId === menuId;
                      //   categoryId === selectedCategory.categoryId;

                      return (
                        <div
                          key={menuId}
                          style={{
                            backgroundColor: isSelected ? '#ffd23c' : '#b0bbc7',
                          }}
                          className="restaurant-menu-item"
                          onClick={() => setSelectedMenu(item)}
                        >
                          {title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <MenuLoading />
            )}
            <div className="restaurant-menu-select-wrapper">
              <div className="restaurant-menu-select-header-text">
                {get(selectedCategory, '[0].categoryResult.categoryName', null)}
              </div>
            </div>
            <div className="restaurant-menu-selected-item-wrapper">
              {searchText.length > 0
                ? searchCategory.map((product: any, index) => {
                    const price = getProductPrice(product);
                    return (
                      <div
                        key={index}
                        onClick={() => onClickProduct(product)}
                        className="restaurant-row-wrapper restaurant-item-margin"
                      >
                        <div className="restaurant-row-line-wrapper">
                          <div
                            className="restaurant-item-wrapper"
                            style={{flexShrink: 0}}
                          >
                            <Image
                              alt={product.productName}
                              menuImage={product.menuImage}
                            />
                          </div>
                          <div className="restaurant-column-line-wrapper restaurant-item-text-margin">
                            <div className="restaurant-item-text">
                              {startCase(product.productName)}
                            </div>
                            <div className="restaurant-item-type-text">
                              {truncate(product.description, {length: 60})}
                            </div>
                          </div>
                        </div>
                        <div className="restaurant-discount-text text-lg">{price}</div>
                      </div>
                    );
                  })
                : selectedMenu?.categories?.map((item: any, index: number) => {
                    const category = categoryMap[item.categoryId];
                    if (!category) {
                      return null;
                    }
                    return (
                      <div className="mb-8">
                        <h3 className="text-lg font-semibold">
                          {category.categoryName}
                        </h3>
                        {item.products.map(({productId}: any) => {
                          if (!productMap[productId]) {
                            return null;
                          }
                          const product = productMap[productId];
                          const price = getProductPrice(product);
                          return (
                            <div
                              key={productId}
                              onClick={() => onClickProduct(product)}
                              className="restaurant-row-wrapper restaurant-item-margin"
                            >
                              <div className="restaurant-row-line-wrapper">
                                <div
                                  className="restaurant-item-wrapper"
                                  style={{flexShrink: 0}}
                                >
                                  <Image
                                    alt={product.productName}
                                    menuImage={product.menuImage}
                                  />
                                </div>
                                <div className="restaurant-column-line-wrapper restaurant-item-text-margin">
                                  <div className="restaurant-item-text">
                                    {startCase(product.productName)}
                                  </div>
                                  <div className="restaurant-item-type-text">
                                    {truncate(product.description, {
                                      length: 60,
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="restaurant-discount-text text-md md:text-lg">
                                {price}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
            </div>

            {loadingProduct ? <ItemLoading /> : null}

            {/* <div className="restaurant-menu-selected-item-wrapper">
              <button
                onClick={() => setOpen(true)}
                className="restaurant-cart-button"
              >
                {'View Cart'}
              </button>
            </div> */}
          </div>
        </div>
        <CartModal
          carts={carts}
          isOpen={isOpen}
          shop={selectedShop}
          onClose={() => setOpen(false)}
        />
      </div>
    </div>
  );
};
export default RestaurantView;
