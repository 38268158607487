import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { useParams } from "react-router-dom";
import { Storage } from "@aws-amplify/storage";
import HomeView from "./modules/home/HomeView";
import MenuView from "./modules/home/MenuView";
import WelcomeView from "./modules/home/WelcomeView";
import awsconfig from "./initialize/config/aws_config";
import MenuItemView from "./modules/restaurant/MenuItemView";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RestaurantView from "./modules/restaurant/RestaurantView";
import ProductContextProvider from "./modules/restaurant/Context";
import EmptyView from "./modules/home/EmptyView";
import TripAdvisorLink from "./modules/shortlinks/TripAdvisor";

Auth.configure(awsconfig);
Amplify.configure(awsconfig);
Storage.configure(awsconfig);

const App = () => {
  const params = useParams();

  return (
    <ProductContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<EmptyView />} />
          <Route path="/t/:shortCode" element={<TripAdvisorLink />} />
          <Route path="/:shortCode" element={<WelcomeView />} />
          <Route path="/:shortCode/:id" element={<WelcomeView />} />
          <Route path="/restaurant/:shopId" element={<RestaurantView />} />
          <Route path="/restaurant/:shopId/:pid" element={<MenuItemView />} />
        </Routes>
      </BrowserRouter>
    </ProductContextProvider>
  );
};

export default App;
