import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../../modules/styles/restaurant-styles.css";
import backIcon from "../../assests/svg/arrow-left.svg";
import { ProductContext } from "../../modules/restaurant/Context";

const BackButton = () => {
  let navigate = useNavigate();
  const { shop } = useContext(ProductContext);

  return (
    <div className="back-button-wrapper">
      <div
        onClick={() => navigate(`/restaurant/${shop.shortCode}`)}
        className="back-button"
      >
        <img src={backIcon} alt="location" style={{}} />
      </div>
    </div>
  );
};

export default BackButton;
